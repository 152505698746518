import { on } from 'delegated-events'
import '@styles/components/dropdown.css'
import easydropdown from 'easydropdown-a11y'

const setupDropdown = (el) => {
  const select = el.querySelector('select')

  if (!select) {
    return
  }

  const opts = {
    behavior: {
      openOnFocus: true,
      maxVisibleItems: 10,
      liveUpdates: true,
      useNativeUiOnMobile: false,
    },
  }

  easydropdown(select, opts)
}

const els = document.querySelectorAll('[data-navigation-dropdown]')

els.forEach((el) => setupDropdown(el))

on('change', '[data-navigation-dropdown] select', (e) => {
  window.location.href = e.target.value
})

window.addEventListener('beforeunload', () => {
  els.forEach((el) => {
    const select = el.querySelector('select')

    if (select && !select.querySelector('[selected]')) {
      select.selectedIndex = -1
    }
  })
})
